import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Api from "../contexts/Api";
import TitleSeparator from "../components/BaseComponents/TitleSeparator";
import { formatDate } from "../contexts/Utils";
import Button from "../components/BaseComponents/Button";

const EventsDetails = (props) => {
  const { event_id } = useParams();
  const [event, setEvent] = useState({});
  const [usersViews, setUsersViews] = useState([])
  const [update, setUpdate] = useState(null);
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;
  const userRole = props.role;
  const navigate = useNavigate();


  //
  // ─── GET EVENTS DATAS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const getEvent = await Api({ endpoint: `/events?event_id=${event_id}`, method: "GET", query: { event_id } });
      const getUsersViews = await Api({ endpoint: `/events/views?event_id=${event_id}`, method: 'GET', });
      setUsersViews(getUsersViews.data);

      setEvent(getEvent.data);
      if (getEvent.success && userRole === 'driver') {
        await Api({ endpoint: '/events/views', data: { event_id } })
      }
      setUpdate(false);
    }
    if (update == null || update) {
      fetchData()
    }
  }, [event_id, update])

  const eventStatus = {
    1: 'Disponible',
    2: 'En cours',
    3: 'Annulé',
    4: 'Terminé',
    5: 'Supprimé',
  }

  //
  // ─── HANDLE EVENT ACTIONS ───────────────────────────────────────
  //
  const handleProposal = async (event_duo_id) => {
    await Api({ endpoint: '/events/proposals', data: { driver_id: user_id, event_duo_id, event_id } })
    setUpdate(true)
  }

  const handleRemoveProposal = async (proposal_id) => {
    await Api({ endpoint: '/events/proposals', method: 'DELETE', data: { proposal_id } })
    setUpdate(true)
  }

  const handleAcceptProposal = async (driver_id, event_duo_id) => {
    await Api({ endpoint: '/events/proposals', method: 'PUT', data: { driver_id, event_id, event_duo_id } })
    setUpdate(true)
  }

  const handleEventUpdate = async (status) => {
    await Api({ endpoint: '/events', method: 'PUT', data: { fields: { status }, event_id } })
    setUpdate(true)
    navigate(`/evenements`);
  }

  const handleCancelDriver = async (status, id) => {
    await Api({ endpoint: '/events', method: 'PUT', data: { fields: { status }, event_id, id, cancel: true } })
    setUpdate(true);
    navigate(`/evenements`);
  }


  return Object.keys(event).length && <>
    <S.DetailContainer>
      <S.RefsContainer>
        <span>{event.reference}</span>
        <span>{eventStatus[event.status]}</span>
      </S.RefsContainer>
      <S.Title>
        <h2>{event.label}</h2>
        <span>{`${event.address}, ${event.postal_code} ${event.city}`}</span>
      </S.Title>
      {event.details && <S.Detail>
        <div>Détails : </div>
        <div>{event.details}</div>
      </S.Detail>}
      <S.EventDetails>
        <S.InfosContainer>
          <TitleSeparator title="Infos" />
          <S.Flex>
            <S.FlexColumn>
              <div>Contact : {event.referent_name}</div>
              <div>Téléphone : {event.phone}</div>
            </S.FlexColumn>
            <S.FlexColumn>
              <div>Nombre de duo : {Object.keys(event.events_duos).length}</div>
            </S.FlexColumn>
          </S.Flex>
        </S.InfosContainer>
        <S.EventDate>
          <div>Date de l'événement :</div>
          <span>{formatDate({ time: event.time_event, display: 'date' })}</span>
        </S.EventDate>
      </S.EventDetails>
    </S.DetailContainer>
    {userRole === 'super-admin' && <S.ButtonContainer>
      {event.status === 1 && <Button variant="error" onClick={() => handleEventUpdate(5)}>Supprimer</Button>}
      {event.status === 2 && <Button variant="error" onClick={() => handleEventUpdate(3)}>Annuler</Button>}
      {event.status === 2 && <Button onClick={() => handleEventUpdate(4)}>Terminer</Button>}
    </S.ButtonContainer>}
    <S.DuoContainer>
      {Boolean(event.events_duos.length) && event.events_duos.map((duos, i) => {

        const hasProposal = duos.events_proposals.filter((proposals) => proposals.driver_id === user_id)

        if (duos.driver_id) return null;

        return <S.TableContainer className="shadow-md rounded-md">
          <S.Table>
            <thead>
              <tr>
                <th>Binôme {i + 1} - {duos.time_start}h à {duos.time_end}h pour {duos.price}€</th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  {userRole === 'driver' && <>
                    {Boolean(!hasProposal.length) && <S.ButtonProposal onClick={() => handleProposal(duos.id)}>Me proposer</S.ButtonProposal>}
                    {Boolean(hasProposal.length) && <S.ButtonProposal onClick={() => handleRemoveProposal(hasProposal[0].id)}>Retirer ma proposition</S.ButtonProposal>}
                  </>}
                </th>
              </tr>
            </thead>

            {userRole === 'super-admin' && <tbody>
              {duos.events_proposals.map((value) => {
                return <tr>
                  <td>{value.users.firstname}</td>
                  <td>{value.users.name}</td>
                  <td>{value.users.email}</td>
                  <td>{value.users.phone}</td>
                  <td><S.Button onClick={() => handleAcceptProposal(value.driver_id, duos.id)}>Choisir</S.Button></td>
                </tr>
              })}
            </tbody>}
          </S.Table>
        </S.TableContainer>
      })}
    </S.DuoContainer>
    <S.TableContainer>
      <S.Table>
        <thead>
          <tr>
            <th></th>
            <th>Chauffeur</th>
            <th>Heure de début</th>
            <th>Heure de fin</th>
            <th>Prix</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Boolean(event.events_duos.length) && event.events_duos.map((duos, i) => {
            if (!duos.users) return null
            if (userRole === 'driver' && duos.driver_id !== user_id) return null;
            console.log(duos)
            return <tr>
              <td>Binôme {i + 1}</td>
              <td>{duos.users.firstname} {duos.users.name} ({duos.users.email})</td>
              <td>{duos.time_start}</td>
              <td>{duos.time_end}</td>
              <td>{duos.price}€</td>
              <S.Actions>
                {(userRole === 'driver' && duos.driver_id === user_id && event.status === 2) && <Button variant="error" onClick={() => handleCancelDriver(1, duos.id)}>Annuler</Button>}
              </S.Actions>
            </tr>
          })}
        </tbody>
      </S.Table>
    </S.TableContainer>
    {userRole === 'super-admin' && <S.Container>
      <h2>Chauffeurs ayant vu l'annonce</h2>
      <S.GridTable>
        <S.TableContainer className="shadow-md rounded-md">
          <S.Table>
            <thead>
              <tr>
                <th>Prénom</th>
                <th>Nom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Jour - heure</th>
              </tr>
            </thead>

            <tbody>
              {usersViews.map((user) => {
                return (
                  <S.Tr key={user.users.user_id} >
                    <td>{user.users.firstname}</td>
                    <td>{user.users.name}</td>
                    <td>{user.users.email}</td>
                    <td>{user.users.phone}</td>
                    <td>{formatDate({ time: user.users.time_create })}</td>
                  </S.Tr>
                );
              })}
            </tbody>
          </S.Table>
        </S.TableContainer>
      </S.GridTable>

    </S.Container>}
  </>
};

export default EventsDetails;

const S = {};

S.Container = styled.div``;

S.DetailContainer = styled.div`
  background:white;
  box-shadow: 0 20px 27px 0 rgb(0 ,0, 0, 5%);
  border-radius: 10px;
  overflow: hidden;
`

S.Flex = styled.div`
  display: flex;
  gap:20px;
  &>div{
    flex:1
  }
`

S.FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
`

S.Button = styled(Button)`
  width:fit-content;
  margin-left:auto ;
`

S.ButtonProposal = styled(Button)`
  width:fit-content;
  margin-left:auto ;
  background:grey;
`

S.RefsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  span{
    padding:10px 20px;
    color:white;
    &:nth-child(1){
      background:#2D2D2D;
    }
    &:nth-child(2){
      background:var(--secondary-color);
    }
  }
`

S.Title = styled.div`
  padding:20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2{
    margin:0;
  }
`

S.EventDetails = styled.div`
  display: flex;
  margin-top: 20px;
`

S.InfosContainer = styled.div`
  padding:0 20px;
  width: 100%;
`

S.EventDate = styled.div`
  width:110px;
  background:red;
  padding:20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:5px;
  color:white;
  background:var(--secondary-color);
  border-top-left-radius: 10px;
  div{
    font-size: 14px;
  }
  span{
    font-weight: bold;
    font-size: 20px;
  }
`

S.DuoContainer = styled.div`
  margin: 20px 0;
`

S.TableContainer = styled.div`
  max-width: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`;

S.Table = styled.table`
  border-radius: 0.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: var(--card-light);
    color: var(--white);
    text-align: left;
  }

  thead > tr > th:first-child {
    border-top-left-radius: 0.5rem;
  }

  thead > tr > th:last-child {
    border-top-right-radius: 0.5rem;
  }

  tbody {
    background: var(--white);
    color: var(--text-color);
  }

  tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid var(--card-dark);
  }

  th,
  td {
    padding: 10px;

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }

  th {
    padding: 10px;
  }
`;

S.Actions = styled.td`
  display: flex;
  gap: 10px;
  align-items: center;

  & svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: var(--primary-color);
  }

  & .shadow-md {
    height: 2rem
  }
`;

S.ButtonContainer = styled.div`
  display:flex;
  justify-content: flex-end;
  gap:10px;
  margin-top: 20px;
`

S.Detail = styled.div`
  padding:20px;
  margin-top: 20px;
  background:#F5F5F5;
  display: flex;
  flex-direction: column;
  gap:10px;
  div:nth-child(1){
    color:#B3B3B3;
  }
`

S.GridTable = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

S.Tr = styled.tr`
  cursor: pointer;
`;